import React from 'react'
import { graphql } from 'gatsby'
import ProductTemplate from 'components/templates/TourTemplate/TourTemplateShared'

const HerculaneumPage = () => {
  const products = {
    0: {
      productId: 'HRCLNTOUR',
      productName: 'bookingTemplate.ercolano.title',
      productImages: {
        0: 'tour/ercolano/ercolano.jpg',
      },
      productLink: 'http://pompeiify.rezgo.com/details/292326/herculaneum-small-group-tour',
      productMaps: 'https://www.google.com/maps/d/embed?mid=1KOHxtEwaoQl1ccy4YtbjvJaNTDULml6k&hl=it',
    },
  }

  const content = {
    overview: 'bookingTemplate.ercolano.overview',
    itineraryContent: 'bookingTemplate.ercolano.itineraryContent',
    firstTime: 'bookingTemplate.ercolano.firstTime',
    duration: 'bookingTemplate.ercolano.duration',
    tPrice: 'bookingTemplate.ercolano.tPrice',
    ePrice: 'bookingTemplate.ercolano.ePrice',
    rTicket: 'bookingTemplate.ercolano.rTicket',
    fTicket: 'bookingTemplate.ercolano.fTicket',
    queue: 'bookingTemplate.ercolano.queue',
    hint: 'bookingTemplate.ercolano.hint',
    place: 'bookingTemplate.ercolano.place',
    faqLink: '/hercolaneum-faq',
  }

  return (
    <>
      <ProductTemplate products={products} content={content} />
    </>
  )
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["translation"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default HerculaneumPage
